import React, { useState, useRef } from "react";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core/styles";
import { encode } from "../../functions/gdpr";
import styles from "./styles";
import "moment/locale/de";

import TextField from "@material-ui/core/TextField";
import CircularProgress from "@material-ui/core/CircularProgress";
import Link from "@material-ui/core/Link";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import CloseIcon from "@material-ui/icons/Close";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
const SyllabusForm = ({
  classes,
  open,
  handleClose,
  isAgreed,
  setIsAgreed,
}) => {
  const [syllabusForm, setSyllabusForm] = useState({
    cablifecycle: "Marketing",
  });
  const [msg, setMsg] = useState(null);
  const [loading, setLoading] = useState(false);
  const webDevRef = useRef(null);
  const dataRef = useRef(null);
  const bothRef = useRef(null);

  const cabAdminPromise = async (formState) => {
    const { strapi } = await encode(formState);
    const data = JSON.stringify(strapi, null, 2);

    const requestOptions = {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        "Authorization": `Bearer ${process.env.GATSBY_STRAPI_TOKEN}`
      },
      body: data
    };
    return fetch(
      `${process.env.GATSBY_STRAPI_URL}/api/leads`,
      requestOptions
    );
  }
  const requestSyllabus = async (formData, course, el) => {
    setLoading(true);
    formData.course = course;
    const hubspot = {
      fields: Object.keys(formData).map((key) => {
        return {
          name: key,
          value: formData[key],
        };
      }),
      legalConsentOptions: {
        // Include this object when GDPR options are enabled
        consent: {
          consentToProcess: true,
          text:
            "I agree to allow Code Academy Berlin to store and process my personal data.",
        },
      },
    };
    try {
      const resp = await fetch(
        `https://api.hsforms.com/submissions/v3/integration/submit/8237502/1ad43945-899d-4fd2-997a-55a7d2d0c661`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(hubspot),
        }
      );
      if (resp) {
        // setLoading(false);
        if (resp.ok) {
          setMsg(null);
          return true;
        } else setMsg("submittedSyllabusErr");
        return false;
      }
    }
    catch (error) {
      console.error('Error:', error);
    }
    finally {
      setLoading(false);
      await cabAdminPromise(formData);

    }
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
    const requestSuccess = await requestSyllabus(
      syllabusForm,
      e.currentTarget.value
    );
    if (requestSuccess) {
      switch (syllabusForm.course) {
        case "web":
          webDevRef.current.click();

          break;
        case "data":
          dataRef.current.click();
          break;
        case "unsure":
          bothRef.current.click();
          break;
        default:
          return;
      }
    }
  };
  const handleChange = (e) => {
    setSyllabusForm({ ...syllabusForm, [e.target.name]: e.target.value });
  };
  const handleAgree = () => {
    setIsAgreed(!isAgreed);
  };
  return (
    <>
      {open && (
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="form-dialog-title"
          scroll="body"
        >
          <DialogActions className={classes.dialogHeader}>
            <CloseIcon
              className={classes.closeIcon}
              onClick={handleClose}
              color="secondary"
            />
          </DialogActions>
          <DialogContent dividers={true}>
            <Grid container spacing={2} className={classes.gridContainer}>
              <Grid item xs={12} sm={12}>
                <TextField
                  required
                  id="firstName"
                  name="firstname"
                  label="First name"
                  fullWidth
                  value={syllabusForm?.firstname ? syllabusForm?.firstname : ""}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <TextField
                  required
                  id="lastName"
                  name="lastname"
                  label="Last name"
                  fullWidth
                  value={syllabusForm?.lastname ? syllabusForm?.lastname : ""}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <TextField
                  required
                  type="email"
                  id="email"
                  name="email"
                  label="Email"
                  fullWidth
                  value={syllabusForm?.email ? syllabusForm?.email : ""}
                  onChange={handleChange}
                />
              </Grid>
            </Grid>
            <div className={classes.privacyMessage}>
              <Grid item xs={12}>
                <FormControlLabel
                  value={isAgreed}
                  onChange={handleAgree}
                  control={<Checkbox color="secondary" name="agree" />}
                  label="I agree to terms and usage of my data. Code Academy Berlin will never sell or use these information for commercial purposes."
                />
              </Grid>
            </div>
            {loading ? (
              <Grid
                item
                xs={12}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: 10,
                  marginBottom: 10,
                }}
              >
                <CircularProgress color="secondary" />
              </Grid>
            ) : (
              <React.Fragment>
                <Grid container spacing={4}
                  style={{
                    justifyContent: "center",
                  }}
                >
                  <Grid item xs={12} sm={4} >
                    <Button
                      onClick={handleSubmit}
                      className={classes.button}
                      underline="none"
                      name="course"
                      value="web"
                      disabled={
                        syllabusForm?.firstname &&
                          syllabusForm?.lastname &&
                          syllabusForm?.email &&
                          isAgreed
                          ? false
                          : true
                      }
                    >
                      Web Development
                    </Button>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Button
                      onClick={handleSubmit}
                      className={classes.button}
                      underline="none"
                      name="course"
                      value="data"
                      disabled={
                        syllabusForm?.firstname &&
                          syllabusForm?.lastname &&
                          syllabusForm?.email &&
                          isAgreed
                          ? false
                          : true
                      }
                    >
                      Data Science
                    </Button>
                  </Grid>

                  <Grid item xs={12} sm={4}>
                    <Button
                      onClick={handleSubmit}
                      className={classes.button}
                      underline="none"
                      name="course"
                      value="unsure"
                      disabled={
                        syllabusForm?.firstname &&
                          syllabusForm?.lastname &&
                          syllabusForm?.email &&
                          isAgreed
                          ? false
                          : true
                      }
                    >
                      Bildungsgutschein
                    </Button>
                  </Grid>
                </Grid>
                <a
                  ref={webDevRef}
                  href="files/Code Academy Berlin - Web Development Syllabus.pdf"
                  download="Syllabus Web Development"
                  target="_blank"
                  style={{
                    display: "hidden",
                  }}
                />
                <a
                  ref={dataRef}
                  href="files/Code Academy Berlin - Data Science Syllabus.pdf"
                  download="Syllabus Data Science"
                  target="_blank"

                  style={{
                    display: "hidden",
                  }}
                />
                <a
                  ref={bothRef}
                  href="files/Code Academy Berlin - Bildungsgutschein Handbook.pdf"
                  target="_blank"

                  download="Bildungsgutschein Handbook"
                  style={{
                    display: "hidden",
                  }}
                />
              </React.Fragment>
            )}
            {msg !== null && (
              <Grid item xs={12} className={classes.errorMessage}>
                {" "}
                {msg}
              </Grid>
            )}
          </DialogContent>
        </Dialog>
      )}
    </>
  );
};

export default withStyles(styles)(SyllabusForm);
